import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import { colors, contentWidth, mobileThresholdPixels } from './v3/styledComponents';
import CTALink from '../CTALink';
import Img from './LookBookImg';
import NewsletterPopup from './NewsletterPopup';

import header from '../../assets/lookbook/header.png';
import headerMobile from '../../assets/lookbook/header.mobile.jpg';
import lookbook11 from '../../assets/lookbook/lookbook1_1.jpg';
import lookbook12 from '../../assets/lookbook/lookbook1_2.jpg';
import lookbook21 from '../../assets/lookbook/lookbook2_1.jpg';
import lookbook22 from '../../assets/lookbook/lookbook2_2.jpg';
import lookbook23 from '../../assets/lookbook/lookbook2_3.jpg';
import lookbook23before from '../../assets/lookbook/lookbook2_3hover.jpg';
import lookbook31 from '../../assets/lookbook/lookbook3_1.jpg';
import lookbook32 from '../../assets/lookbook/lookbook3_2.jpg';
import lookbook32before from '../../assets/lookbook/lookbook3_2hover.jpg';
import lookbook33 from '../../assets/lookbook/lookbook3_3.jpg';
import lookbook34 from '../../assets/lookbook/lookbook3_4.jpg';
import lookbook41 from '../../assets/lookbook/lookbook4_1.jpg';
import lookbook41mobile from '../../assets/lookbook/lookbook4_1.mobile.jpg';
import lookbook42 from '../../assets/lookbook/lookbook4_2.jpg';
import lookbook43 from '../../assets/lookbook/lookbook4_3.jpg';
import lookbook43before from '../../assets/lookbook/lookbook4_3hover.jpg';
import lookbook51 from '../../assets/lookbook/lookbook5_1.jpg';
import lookbook52 from '../../assets/lookbook/lookbook5_2.jpg';
import lookbook53 from '../../assets/lookbook/lookbook5_3.jpg';
import lookbook61 from '../../assets/lookbook/lookbook6_1.jpg';
import lookbook61Mobile from '../../assets/lookbook/lookbook6_1.mobile.jpg';
import lookbook62 from '../../assets/lookbook/lookbook6_2.jpg';
import lookbook63 from '../../assets/lookbook/lookbook6_3.jpg';
import lookbook64 from '../../assets/lookbook/lookbook6_4.jpg';
import lookbook71 from '../../assets/lookbook/lookbook7_1.jpg';
import lookbook72 from '../../assets/lookbook/lookbook7_2.jpg';
import lookbook73 from '../../assets/lookbook/lookbook7_3.jpg';
import lookbook73before from '../../assets/lookbook/lookbook7_3hover.jpg';

const Container = styled.div`
  max-width: ${contentWidth}px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 100vw;
    margin: 0px 18px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const RowFirstBlock = styled(Row)`
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => props.width && `width: ${props.width}px;`}
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.marginRight && `margin-right: ${props.marginRight}px;`}

  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 0px;
  }
`;

const HeaderContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
  }
`;

const HeaderImage = styled.img`
  max-width: ${contentWidth}px;
  margin-top: 80px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    margin: auto;
  }
`;

const HeaderTitle = styled.h1`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 46px;
  line-height: 58px;
  letter-spacing: 7px;
  color: ${colors.navy};
  text-align: left;
  margin: 0px;
`;

const HeaderSubTitle = styled.h2`
  font-family: Libre Baskerville;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 3px;
  text-align: left;
  margin: 4px 0px 10px;
`;

const IntroContainer = styled.div`
  flex-direction: row;
  align-items: center;
  width: 1100px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 17px;
    width: 340px;
  }
`;

const Intro = styled.p`
  font-family: Roboto;
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  letter-spacing: 1px;
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 17.4px;
    line-height: 27px;
    text-align: justify;
  }
`;

const IntroRow = styled(Row)`
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: row;
  }
`;

const IntroFirstLetter = styled(Intro)`
  font-size: 104px;
  padding-top: 20px;
  margin-bottom: -20px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 54px;
    padding-top: 16px;
    margin-bottom: -16px;
    margin-right: 4px;
  }
`;

const LineHorizontal = styled.div`
  background-color: ${colors.black};
  height: 1px;
  width: 148px;
  margin: 56px auto 128px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 33px auto 39px;
    width: 93px;
  }
`;

const TextFirstBlock = styled.p`
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  margin-right: 50px;
  margin-left: 50px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 23px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const SecondTitleContainer = styled.div`
  margin-top: 143px;
  margin-bottom: 130px;
  position: relative;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 67px;
    margin-bottom: 66px;
  }
`;

const GreyBlock = styled.div`
  z-index: 0;
  position: absolute;
  top: 28px;
  width: 100%;
  height: 36px;
  background-color: rgba(225, 225, 225, 0.42);

  @media (max-width: ${mobileThresholdPixels}) {
    top: 64px;
  }
`;

const Title2 = styled.h2`
  z-index: 1;
  position: relative;
  font-family: Roboto;
  font-size: 37px;
  font-weight: 400;
  line-height: 47px;
  text-align: center;
  letter-spacing: 13.5px;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-weight: normal;
    font-size: 22px;
    line-height: 42px;
    letter-spacing: 10px;
  }
`;

const SecondBlockTitle = styled.h2`
  margin: 0px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin-bottom: 4px;
  }
`;
const SecondBlockText = styled.p`
  margin: 0px;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 24px;
    ${props => props.small && 'font-style: italic;'}
  }
`;

const ThirdTitleContainer = styled.div`
  margin-top: 143px;
  margin-bottom: 130px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 38px;
    margin-bottom: 40px;
  }
`;

const ThirdTitle = styled.h2`
  margin: 0px 0px 43px;
  font-family: Libre Baskerville;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 83px;
  line-height: 89px;
  text-align: center;
  letter-spacing: 22px;
  color: ${colors.navy};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 24px;
    line-height: 79px;
    letter-spacing: 14px;
    margin-bottom: 0px;
  }
`;

const ThirdTitleSubtitle = styled.p`
  margin: 0px;
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${colors.black};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 23px;
  }
`;

const ThirdBlockText = styled.p`
  margin: auto;
  font-family: Roboto;
  font-size: ${props => props.small ? 12 : 18}px;
  line-height: ${props => props.lineHeight || 17}px;
  letter-spacing: 2px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    font-size: ${props => props.small ? 12 : 14}px;
    line-height: ${props => props.lineHeight || 17}px;
  }
`;

const FourthTitleContainer = styled.div`
  margin-top: 143px;
  margin-bottom: 130px;
  position: relative;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 60px;
    margin-bottom: 40px;
  }
`;

const FourthTitle = styled.h2`
  position: relative;
  z-index: 1;
  margin: 0px 100px;
  font-family: Roboto;
  font-weight: normal;
  font-style: italic;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  letter-spacing: 1.5px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 320px;
    max-width: calc(100vw - 36px);
  }
`;

const FourthTitleQuote = styled.span`
  position: absolute;
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: normal;
  font-size: 200px;
  line-height: 40px;
  letter-spacing: 1.5px;
  color: rgba(0, 0, 0, 0.1);

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 100px;
  }
`;
const FourthTitleOpeningQuote = styled(FourthTitleQuote)`
  top: 34px;
  left: 44px;

  @media (max-width: ${mobileThresholdPixels}) {
    top: 0px;
    left: 0px;
  }
`;
const FourthTitleClosingQuote = styled(FourthTitleQuote)`
  bottom: -45px;
  right: 85px;

  @media (max-width: ${mobileThresholdPixels}) {
    bottom: -60px;
    right: 0px;
  }
`;

const FourthBlockTitle = styled.div`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 24px;
  line-height: 89px;
  text-align: center;
  letter-spacing: 14px;
  color: ${colors.navy};
`;

const FourthBlockText = styled.p`
  margin: 51px auto 63px;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin: 30px 0px;
  }
`;

const FifthTitleContainer = styled(SecondTitleContainer)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 50px;
  }
`;

const FifthTitle = styled.h2`
  position: relative;
  z-index: 1;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 37px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 13.5px;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 22px;
    line-height: 42px;
    letter-spacing: 10px;
  }
`;

const FifthTitleGreyBlock = styled(GreyBlock)`
  top: 74px;
`;

const FifthBlockText = styled.p`
  margin: 0px;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  ${props => props.center && 'text-align: center;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const SixthBlockRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const SixthBlockText = styled.p`
  margin: 0px;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const SeventhBlockText = styled.p`
  margin: 0px;
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Footer = styled.div`
  flex-direction: column;
  align-items: center;
  margin: 140px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 70px 0px;
  }
`;

const FooterText = styled.p`
  margin: 0px auto 60px;
  text-align: center;
`;

const FooterButtonContainer = styled.div`
  text-align: center;
`;

const renderHeader = isMobile => (
  <HeaderContainer>
    <HeaderImage src={isMobile ? headerMobile : header} alt="Lookbook upcycling et transformations de vêtements" />
    {isMobile &&
      <div>
        <HeaderTitle>LOOKBOOK HIVER.</HeaderTitle>
        <HeaderSubTitle>VOL1. 2019 - 2020</HeaderSubTitle>
      </div>
    }
  </HeaderContainer>
);

const renderIntro = isMobile => (
  <IntroContainer>
    <div>
      <IntroRow>
        <IntroFirstLetter>D</IntroFirstLetter>
        {isMobile
          ? (
            <Intro>
              epuis la naissance de Tilli, notre
              ambition est de vous prouver que
            </Intro>
          )
          : (
            <Intro>
              epuis la naissance de Tilli, notre ambition est de vous prouver que la retouche,<br />
              la réparation et l’upcycling sont à la fois un moyen de faire plus attention à sa
            </Intro>
          )
        }
      </IntroRow>
      <Intro>
        {isMobile
          ? `la retouche, la réparation et l’upcycling sont à la fois un moyen de
          faire plus attention à sa consommation de vêtements et une manière de
          laisser parler sa créativité !`
          : 'consommation de vêtements et une manière de laisser parler sa créativité !'
        }
      </Intro>
    </div>
    <LineHorizontal />
  </IntroContainer>
);

const renderFirstBlock = isMobile => (
  <RowFirstBlock>
    <Column marginRight={18} flex={580}>
      <Img
        src={lookbook11}
        alt="Retouche robe rouge"
        marginBottom={isMobile ? 10 : 0}
        loading="lazy"
      />
    </Column>
    <Column flex={642}>
      <Img src={lookbook12} alt="Transformation robe rouge" loading="lazy" />
      <TextFirstBlock>
        C’est en ce sens que nous lançons <b>notre lookbook d’inspirations</b>. Des inspirations
        de retouches, de réparations et d’upcycling qui mélangeront tous les styles,
        du plus simple au plus complexe.
      </TextFirstBlock>
      <TextFirstBlock>
        Cette première série d’inspirations, nous l’avons réalisée avec Beryl, la fondatrice
        de Tilli. Elle revisite les vêtements hérités de sa grand-mère et les basiques de sa
        garde robe pour vous montrer tout ce qu’il est possible de faire à partir de l’existant.
      </TextFirstBlock>
    </Column>
  </RowFirstBlock>
);

const renderSecondTitle = () => (
  <SecondTitleContainer>
    <GreyBlock />
    <Title2>
      J’ai <b><i>osé</i></b> twister la robe balmain iconique de ma grand-mère.
    </Title2>
  </SecondTitleContainer>
);

const renderSecondBlock = isMobile => (
  <RowFirstBlock>
    <Column flex={628} marginRight={18}>
      <Img src={lookbook21} alt="Cintrage robe" marginBottom={isMobile ? 10 : 18} loading="lazy" />
      <Img src={lookbook22} alt="Ourlet de robe" marginBottom={isMobile ? 10 : 0} loading="lazy" />
    </Column>
    <Column flex={637}>
      <Img
        src={lookbook23}
        beforeSrc={lookbook23before}
        alt="Elagir vêtement"
        marginBottom={isMobile ? 10 : 26}
        loading="lazy"
      />

      <SecondBlockTitle>La robe Balmain iconique des 50’s</SecondBlockTitle>
      <SecondBlockText>
        {!isMobile && <br />}
        Cintrage du buste : 39€<br />
        Cintrage des épaules : 30€<br />
        Cintrage des manches : 35€<br />
        Ourlet simple sur soie doublée : 44€<br />
      </SecondBlockText>
    </Column>
  </RowFirstBlock>
);

const renderThirdTitle = isMobile => (
  <ThirdTitleContainer>
    <ThirdTitle>
      Seconde vie
    </ThirdTitle>
    <ThirdTitleSubtitle>
      Demandez votre étiquette mode durable à votre Tilliste lors de votre RDV.
      {isMobile ? ' ' : <br />}
      <b>Soyez fiers, soyez Tilli !</b>
    </ThirdTitleSubtitle>
  </ThirdTitleContainer>
);

const renderThirdBlock = isMobile => (
  <RowFirstBlock>
    <Column marginRight={18} flex={612}>
      <Img marginBottom={isMobile ? 10 : 18} src={lookbook31} alt="Retouche jeans" loading="lazy" />
      {!isMobile && (<Img
        marginBottom={isMobile ? 10 : 18}
        src={lookbook32}
        beforeSrc={lookbook32before}
        alt="Ourlet revers pantalon"
        loading="lazy"
      />)}
      {!isMobile &&
        <ThirdBlockText>
          <ThirdBlockText lineHeight={24}>
            <b>Jean basique & Other Stories</b><br />
          </ThirdBlockText>
          <br />
          Ajout de deux genouillères : 17€<br /><br />
          Ajout d’une poche : 20€
        </ThirdBlockText>
      }
    </Column>
    <Column flex={655}>
      <Img marginBottom={isMobile ? 10 : 18} src={lookbook33} alt="Ajout d'une poche sur un chino" loading="lazy" />
      <Img marginBottom={isMobile ? 10 : 0} src={lookbook34} alt="Ajout de genouillères pour un pantalon" loading="lazy" />
      {isMobile && (<Img
        marginBottom={isMobile ? 10 : 18}
        src={lookbook32}
        beforeSrc={lookbook32before}
        alt="Ourlet revers pantalon"
        loading="lazy"
      />)}
      {isMobile &&
        <ThirdBlockText lineHeight={24}>
          <ThirdBlockText lineHeight={24}>
            <b>Jean basique & Other Stories</b><br />
          </ThirdBlockText>
          Ajout de deux genouillères : 17€<br />
          Ajout d’une poche : 20€
        </ThirdBlockText>
      }
    </Column>
  </RowFirstBlock>
);

const renderFourthTitle = () => (
  <FourthTitleContainer>
    <FourthTitleOpeningQuote>“</FourthTitleOpeningQuote>
    <FourthTitle>
      <b>Ce jean, c’est le basique qu’on voit dans tous les dressings. </b>
      Je voulais le rendre plus singulier. Avec Carole, ma Tilliste, on a
      récupéré des morceaux de tissus d’un vieux short blanc que je ne portais
      plus et d’un jean trop usé.
    </FourthTitle>
    <FourthTitleClosingQuote>”</FourthTitleClosingQuote>
  </FourthTitleContainer>
);

const renderFourthBlock = isMobile => (
  <RowFirstBlock>
    <Column>
      {isMobile && <FourthBlockTitle>UPCYCLING</FourthBlockTitle>}
      <Img src={isMobile ? lookbook41mobile : lookbook41} alt="Réparation trous de mites" loading="lazy" />
      <FourthBlockText>
        Remaillage de 16 trous de mites : 96€ {isMobile && <br />}(6€/trous)<br />
        {isMobile && <br />}
        Ourlet de maille : 14€
      </FourthBlockText>
      <Row>
        <Column flex={601} marginRight={18}>
          <Img src={lookbook42} alt="Trous de mites" marginBottom={isMobile ? 10 : 0} loading="lazy" />
        </Column>
        <Column flex={660}>
          <Img src={lookbook43} beforeSrc={lookbook43before} alt="Robe noire remaillage trou de mites" loading="lazy" />
        </Column>
      </Row>
    </Column>
  </RowFirstBlock>
);

const renderFifthTitle = isMobile => (
  <FifthTitleContainer>
    {!isMobile &&
      <FifthTitleGreyBlock />
    }
    <FifthTitle>
      Cette robe n’était pas sortie du placard depuis plus de <b><i>10 ans</i></b>.{isMobile ? <br /> : ''}
      <i>Elle était dévastée par les mites, n’importe qui l’aurait jetée !</i>
    </FifthTitle>
  </FifthTitleContainer>
);

const renderFifthBlock = isMobile => (
  <div>
    <Row>
      <Column flex={538} marginRight={18}>
        <Img
          src={lookbook51}
          marginBottom={isMobile ? 10 : 18}
          alt="Réparations de tous les boutons d'une veste"
          loading="lazy"
        />
      </Column>
      <Column flex={723} justifyContent="flex-end">
        {!isMobile &&
          <FifthBlockText>
            Réparation de 5 boutons de veste : 15€ (3€/bouton de veste)<br />
            Doublure de poche : 40€ (20€/poche)<br />
          </FifthBlockText>
        }
        <Img src={lookbook52} alt="Doublure de poche d'un manteau" marginBottom={isMobile ? 10 : 18} loading="lazy" />
        {isMobile &&
          <Img src={lookbook53} alt="Veste chinée chez Violette Sauvage" marginBottom={10} loading="lazy" />
        }
        {isMobile &&
          <FifthBlockText>
            Réparation de 5 boutons de veste : 15€<br />
            (3€/bouton de veste)<br />
            <br />
            Doublure de poche : 40€<br />
            (20€/poche)<br />
          </FifthBlockText>
        }
      </Column>
    </Row>
    <Row>
      <Column flex={538} marginRight={18}>
        <FifthBlockText center>
          <b>Veste chinée chez Violette Sauvage</b>
        </FifthBlockText>
      </Column>
      {!isMobile &&
        <Column flex={723}>
          <Img src={lookbook53} alt="Veste chinée chez Violette Sauvage" marginBottom={225} loading="lazy" />
        </Column>
      }
    </Row>
  </div>
);

const renderSixthBlock = isMobile => (
  <Column>
    {isMobile && <FourthBlockTitle>RE-USE</FourthBlockTitle>}
    <Img
      src={isMobile ? lookbook61Mobile : lookbook61}
      alt="robe japonaise Jacques Molko"
      marginBottom={isMobile ? 41 : 121}
      loading="lazy"
    />
    <SixthBlockRow>
      <Column flex={1} marginRight={!isMobile && 10}>
        <Img
          src={lookbook62}
          alt="Ajout d’un bouton pression"
          loading="lazy"
        />
      </Column>
      <Column flex={1} marginRight={!isMobile && 10}>
        <Img
          marginTop={isMobile ? 10 : 0}
          marginBottom={isMobile ? 10 : 0}
          src={lookbook63}
          alt="Retouche robe"
          loading="lazy"
        />
      </Column>
      <Column flex={1}>
        <Img
          src={lookbook64}
          alt="Bouton de robe verte"
          loading="lazy"
        />
      </Column>
    </SixthBlockRow>
    <SixthBlockText>
      <br />
      <b>La robe japonaise Jacques Molko de ma grand-mère</b><br />
      <br />
      Ajout d’un bouton pression : 7€
    </SixthBlockText>
  </Column>
);

const renderSixthTitle = () => (
  <FourthTitleContainer>
    <FourthTitleOpeningQuote>“</FourthTitleOpeningQuote>
    <FourthTitle>
      Ma grand-mère portait cette robe d’inspiration japonaise pour sortir le soir...
      Le côté satiné était trop habillé pour moi, j’en ai fait une robe de tous les jours
      en l’ajustant.
      <b> Une grosse ceinture, des boots et le tour est joué !</b>
    </FourthTitle>
    <FourthTitleClosingQuote>”</FourthTitleClosingQuote>
  </FourthTitleContainer>
);

const renderSeventhBlock = isMobile => (
  <RowFirstBlock>
    <Column marginRight={18} flex={644}>
      <Img src={lookbook71} alt="Gilet en laine Hervé Chapelier" marginBottom={isMobile ? 10 : 18} />
      <Img src={lookbook72} alt="Remplacement de deux boutons" />
    </Column>
    <Column flex={623}>
      <Img
        src={lookbook73}
        beforeSrc={lookbook73before}
        alt="Réparation pull et gilets"
        marginTop={isMobile ? 10 : 174}
        marginBottom={isMobile ? 10 : 18}
        loading="lazy"
      />
      <SeventhBlockText>
        <b>Gilet en laine Hervé Chapelier</b><br />
        <br />
        Remplacement de deux boutons : 4€
        {isMobile && <br />}(2€/bouton)
      </SeventhBlockText>
    </Column>
  </RowFirstBlock>
);

const renderFooter = isMobile => (
  <Footer>
    {!isMobile &&
      <FooterText>
        Ce Lookbook est le résultat du travail réalisé par nos Tillistes :
        Alexandra, Chantal & Carole. Merci à elles !
      </FooterText>
    }
    <FooterButtonContainer>
      <CTALink fromInteraction="Lookbook_button" />
    </FooterButtonContainer>
  </Footer>
);

const LookBook = ({ context: { isMobile }, newsletter }) => (
  <Container>
    {renderHeader(isMobile)}
    {renderIntro(isMobile)}
    {renderFirstBlock(isMobile)}
    {renderSecondTitle(isMobile)}
    {renderSecondBlock(isMobile)}
    {renderThirdTitle(isMobile)}
    {renderThirdBlock(isMobile)}
    {renderFourthTitle(isMobile)}
    {renderFourthBlock(isMobile)}
    {renderFifthTitle(isMobile)}
    {renderFifthBlock(isMobile)}
    {renderSixthBlock(isMobile)}
    {renderSixthTitle(isMobile)}
    {renderSeventhBlock(isMobile)}
    {renderFooter(isMobile)}
    {newsletter &&
      <NewsletterPopup />
    }
  </Container>
);

LookBook.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  newsletter: PropTypes.bool,
};

LookBook.defaultProps = {
  newsletter: false,
};

export default withAppContext(LookBook);
