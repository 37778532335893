import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from './v3/styledComponents';
import arrowRight from '../../assets/lookbook/arrow.right.png';
import arrowLeft from '../../assets/lookbook/arrow.left.png';

const Container = styled.div`
  position: relative;
  ::selection {
    background-color: rgba(0,0,0,0);
  }
`;

const Img = styled.img`
  width: 100%;
  ${props => props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ''}
  ${props => props.marginTop ? `margin-top: ${props.marginTop}px;` : ''}
  ${props => props.marginLeft ? `margin-left: ${props.marginLeft}px;` : ''}
  ${props => props.marginRight ? `margin-right: ${props.marginRight}px;` : ''}
  ::selection {
    background-color: rgba(0,0,0,0);
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 1;
`;

const Arrow = styled.img`
  margin: 0px 45px 20%;
  ::selection {
    background-color: rgba(0,0,0,0);
  }

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 25px 20%;
  }
`;

const Footer = styled.div`
  position: absolute;
  text-align: center;
  bottom: 40px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterText = styled.span`
  color: ${colors.white};
  font-weight: bold;
  font-size: 18px;
  line-height: 13px;
  letter-spacing: 2px;
  ::selection {
    background-color: rgba(0,0,0,0);
  }
`;

const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Dot = styled.div`
  flex: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.isSelected ? colors.white : colors.lightGrey3};
  margin: 11px 2.5px;
  transition: all 0.5s ease;
`;

function LookBookImg({ src: defaultSrc, beforeSrc, ...props }) {
  const [isBefore, setIsBefore] = useState(0);
  const src = (isBefore && beforeSrc) ? beforeSrc : defaultSrc;

  if (!beforeSrc) return (<Img {...props} src={src} />);

  return (
    <Container>
      <Img {...props} src={src} />

      <ArrowContainer onClick={() => setIsBefore(!isBefore)}>
        <Arrow left src={arrowLeft} />
        <Arrow right src={arrowRight} />
      </ArrowContainer>

      <Footer>
        <FooterText>{isBefore ? 'Avant' : 'Après'}</FooterText>
        <DotsContainer>
          <Dot isSelected={!isBefore} />
          <Dot isSelected={isBefore} />
        </DotsContainer>
      </Footer>
    </Container>
  );
}

LookBookImg.propTypes = {
  src: PropTypes.string.isRequired,
  beforeSrc: PropTypes.string,
};

LookBookImg.defaultProps = {
  beforeSrc: undefined,
};

export default LookBookImg;
