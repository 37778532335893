import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withAppContext from '../../withAppContext';
import closeImg from '../../assets/menuClose.svg';

const NewsletterPopupContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
`;

const Close = styled.div`
  z-index: 1;
  position: absolute;
  top: 35px;
  right: 35px;
`;

const newsletterPopin = isMobile => `
<iframe
  class="mj-w-res-iframe"
  frameborder="0"
  scrolling="no"
  marginheight="0"
  marginwidth="0"
  height="${isMobile ? 600 : 400}"
  width="100%"
  src="https://app.mailjet.com/widget/iframe/247p/jPF"
></iframe>

<script type="text/javascript" src="https://app.mailjet.com/statics/js/iframeResizer.min.js"></script>
`;

const LookBookNewsletterPopup = ({ context: { isMobile } }) => {
  const [isOpen, setIsOpen] = useState(true);
  if (!isOpen) return null;
  return (
    <NewsletterPopupContainer onClick={() => setIsOpen(false)}>
      <div style={{ position: 'relative' }}>
        <Close>
          <img src={closeImg} alt="fermer" onClick={() => setIsOpen(false)} />
        </Close>
        <div
          style={{ width: 1040, maxWidth: '100vw' }}
          dangerouslySetInnerHTML={{ __html: newsletterPopin(isMobile) }}
        />
      </div>
    </NewsletterPopupContainer>
  );
};

LookBookNewsletterPopup.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(LookBookNewsletterPopup);
